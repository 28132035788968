import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';
import { hostingurl } from '../../../utils/helper-functions';

export const signupUser = createAsyncThunk('users/signupUser', async ({ body }, thunkAPI) => {
	try {
		const response = await axios.post(`${SERVER_URL}/account/register`, { ...body,source:'manual', hostingurl });
		const { data } = response;
		if (response.status === 200) {
			localStorage.setItem('token', data?.result?.token);
			return { ...data, username: body.name, email: body.email };
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e) {
		return thunkAPI.rejectWithValue(e.response.data);
	}
});
